<template>
  <div class="containers">
    <div class="selfStyle">
      <div class="title">新员工入职协议书</div>
      <div class="inputBox">
        <div class="inputs">
          甲方：{{query.company_name}}
        </div>
        <div class="inputs">
          <span style="flex-shrink: 0">乙方：</span>
          <van-field class="inputsItem" v-model="query.name" />
        </div>
        <div class="inputs">
          <span style="flex-shrink: 0">身份证号：</span>
          <van-field class="inputsItem" v-model="query.ID_number" />
        </div>
        <div class="textIndent">
          经甲乙双方平等协商，就乙方的工作岗位、试用期/转正后的薪资福利待遇、转正后的服务期限、培养费等问题达成如下协议：
        </div>
        <div class="contract-term selfStyle">
          <span class="textIndent">
            一、乙方于
          </span>
          <span class="date-placeholder">
            <van-field disabled class="inputs input50" style="width: 60px;" />
          </span>
          年
          <span class="date-placeholder">
            <van-field disabled class="inputs input50" style="width: 60px;" />
          </span> 月
          <span class="date-placeholder">
            <van-field disabled class="inputs input50" style="width: 60px;" />
          </span> 日起就职于甲方
          <van-field disabled class="inputs" style="width: 80%"/>
          (部门)
          <van-field disabled class="inputs"/>
          (岗位)，试用期限为1-3个月。
        </div>
        <div class="contract-term selfStyle">
          <div class="textIndent">
            二、甲方实行新员工7天无薪考核管理办法，即入职7天内甲方对乙方工作表现不认可被辞退的，甲方将不承担乙方薪水；入职7天内因乙方个人原因离职的，甲方同样不承担乙方薪水。
          </div>
          <div class="textIndent">
            三、乙方通过7天无薪考核期继续工作的，由于甲方对乙方继续试用工作表现不认可辞退的，则按出勤天数发放试用期薪水（含7天无薪考核期薪水）。
          </div>
          <div class="textIndent">
            四、为维护甲方权益，在试用期内由乙方单方面提出辞职的或违反公司相关规章制度被辞退的，将扣除乙方7天无薪考核期工资，剩余薪水按甲方薪水发放流程发放。
          </div>
          <div class="textIndent">
            五、在乙方为甲方服务期限内，甲方有义务为乙方提供相关业务/技能/岗位等方面的培训。
          </div>
          <div class="textIndent">
            六、乙方自愿接受甲方组织安排的各项业务/技能/岗位等的培训，服从甲方安排。
          </div>
          <div class="textIndent">
            七、为维护甲方的权益，乙方在承诺的服务期限届满前离职或被辞退的，自愿支付公司在这段时间对本人的培训费（7天薪资）。
          </div>
          <div class="textIndent">
            八、离职当月按照实际出勤天数计算当月工资，除基本工资外，不享受公司的其他福利待遇。
          </div>
          <div class="textIndent">
            九、其他约定事项：
          </div>
          <van-field disabled class="inputs" />

        </div>
        <div class="itemTitle">甲方（盖章）：</div>
        <img v-if="query.seal_path" class="hetong" :src="query.seal_path" alt="">
        <div class="itemTitle contract-term selfStyle">
          日期：
          <span class="date-placeholder">
        <van-field disabled class="inputs input50" style="width: 60px;" />
        </span>年
          <span class="date-placeholder">
        <van-field disabled class="inputs input50" style="width: 60px;" />
        </span>月
          <span class="date-placeholder">
        <van-field disabled class="inputs input50" style="width: 60px;" />
        </span>日
        </div>
        <div class="sign">
          <span class="signLabel" style="font-weight: bold; color: #666666">乙方签字:</span>
          <img v-if="query.sign_file"  class="card" :src="query.sign_file" alt="">
          <vue-esign v-else  class="card" ref="esign" :height="350" :isCrop="false" :lineWidth="10" :lineColor="'#000'" />
        </div>
        <div style="display: flex;justify-content: flex-end">
          <div class="reset" @click="resetSign">重新签名</div>
        </div>
        <div class="titlesFlex">
          <span style="font-weight: bold; color: #666666">日期：</span>
          <van-field class="inputs inputBorder36" v-model="query.select_year" />
          年
          <van-field class="inputs inputBorder36" v-model="query.select_month" />
          月
          <van-field class="inputs inputBorder36" v-model="query.select_day" />
          日
        </div>
      </div>


      <div class="submitBtn" @click="submitInfo">保存</div>
    </div>
  </div>
</template>

<script>
import {LoadProtocol, SaveProtocol, uploadFile} from "@/api/paper";
import axios from "axios";

export default {
  data() {
    return {
      query: {},
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    resetSign() {
      if(this.query.sign_file) {
        this.query.sign_file = ''
      } else {
        this.$refs.esign.reset()
      }
    },
    loadData() {
      LoadProtocol({openId: this.$store.state.info.openId}).then(res => {
        if(res.row) {
          this.query = res.row
        }
      })
    },
    submit() {
      this.query.openId = this.$store.state.info.openId
      SaveProtocol(this.query).then(res => {
        if(res.result) {
          this.$notify({
            type: "success",
            message: "提交成功",
          });
          this.$router.go(-1)
        }
      })
    },
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    async drawImg() {
      this.$refs.esign.generate().then(res => {
        var blob = this.dataURLtoBlob(res)
        var tofile = this.blobToFile(blob, '签名.png')
        setTimeout(async () => {
          const formData = new FormData()
          formData.append('file', tofile, tofile.name)
          uploadFile(formData).then(res => {
            this.query.sign_file = res.filePath
            this.query.sign_pic_name = res.picName
            this.submit()
          })
        })
      })
    },
    submitInfo() {
      if(!this.query.select_year || !this.query.select_month || !this.query.select_day) {
        return this.$notify({
          type: "warning",
          message: "请将日期填写完整",
        });
      }
      if(!this.query.sign_file && !this.$refs.esign.hasDrew ) {
        return this.$notify({
          type: "warning",
          message: "请签名~",
        });
      }
      if(this.query.sign_file) {
        this.submit()
      } else {
        this.drawImg()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.containers {
  box-sizing: border-box;
  padding: 14px 10px;
}
.inputs {
  display: flex;
  align-items: center;
  .inputsItem {
    font-size: 12px;
    border-bottom: 1px solid #DDDDDD;
    text-align: center !important;
    padding: 0 !important;
  }
}
.inputBox {
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  color: #666666;
  line-height: 20px;
  .itemTitle {
    font-weight: bold;
  }
}
.tips {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 11px;
  color: #777777;
  line-height: 18px;
  margin: 18px 0;
}
.sign {
  margin-top: 50px;
  .signLabel {
    flex-shrink: 0;
    font-family: PingFang SC;
    font-size: 15px;
    color: #666666;
  }
  .card {
    width: 100%;
    height: 70px;
    background: #F7F8FA;
    border: 1px solid #DDDDDD;
  }
}
.reset {
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #02DBCA;
  color: #02DBCA;
  border-radius: 10px;
  margin-top: 15px;
}
.submitBtn {
  background: #02DBCA;
  border-radius: 20px;
  color: #FFFFFF;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.titlesFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.inputBorder36 {
  width: 36px;
}
.inputs {
  font-size: 12px;
  border-bottom: 1px solid #DDDDDD;
  padding: 0 !important;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 60px 0;
  color: #000000;
}
.input50 {
  width: 45px !important;
}
.input130 {
  width: 130px;
}
.input80 {
  width: 80px;
}
.textIndent {
  text-indent: 2em;
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.contract-term {
  display: flex;
  align-items: center; /* 垂直居中 */
  flex-wrap: wrap; /* 允许内容自动换行 */
}
.hetong {
  width: 180px;
  height: 180px;
}
</style>
<style>
.selfStyle .van-field__control {
  text-align: center !important;
}
</style>
